import React, { useEffect, useState } from "react";
import { getStoreById } from "../../config/securityInstance";
import TopHeadNameBarItemsMain from "../../components/UI/TopHeadNameBarItemsMain";
import ImageSimpleSlider from "../../components/UI/ImageSlider";
import Rating from "@mui/material/Rating";
import { Link, useLocation } from "react-router-dom";

function CategoriesItemMainView() {
  const idcode = window.location.pathname.split("/").pop();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const keyword = query.get("keyword");
  
  const [storeDetails, setStoreDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStoreDetails = async () => {
      try {
        const data = await getStoreById(idcode);
        if (keyword) {
          // Filter based on the tagline if keyword is provided
          data.tagline = data.tagline || ""; // Ensure tagline is a string
          if (data.tagline.toLowerCase().includes(keyword.toLowerCase())) {
            setStoreDetails(data);
          } else {
            setStoreDetails(null);
          }
        } else {
          setStoreDetails(data);
        }
      } catch (error) {
        setError("Failed to fetch store details.");
      } finally {
        setLoading(false);
      }
    };

    fetchStoreDetails();
  }, [idcode, keyword]);

  if (loading) {
    return (
      <div className="py-24">
        <div className="max-w-md mx-auto p-4 border rounded-lg shadow-lg bg-gray-200 animate-pulse">
          <div className="h-48 bg-gray-300 rounded-lg"></div>
          <div className="mt-4">
            <div className="h-6 bg-gray-300 rounded w-3/4 mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-1/2 mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-1/2 mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-1/3 mb-2"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="py-24 text-center text-red-500">{error}</div>;
  }

  if (!storeDetails) {
    return <div className="py-24 text-center">No store found.</div>;
  }

  return (
    <div className="py-24">
      <TopHeadNameBarItemsMain items={storeDetails} />
      <div className="w-full flex justify-center">
        <div className="container mx-auto p-4 md:p-20 antialiased space-x-0 md:space-x-12 space-y-8 md:space-y-0 flex flex-col md:flex-row items-center">
          <div className="justify-center flex flex-wrap md:flex-wrap shadow-lg w-full md:w-7/12 mx-auto group cursor-pointer transform duration-500 rounded-xl">
            <ImageSimpleSlider images={storeDetails.images} />
            <div className="text-left w-full">
              <div className="p-5">
                <h1 className="text-2xl font-semibold text-gray-800 mt-4">
                  {storeDetails.shopDetails.shopName}
                </h1>
                <p className="text-xl text-gray-400 mt-2 leading-relaxed">
                  {storeDetails.shopDetails.shopDescription}
                </p>
                <p className="text-xl text-gray-400 mt-2 leading-relaxed">
                  {storeDetails.shopLocationDetails.shopLocation}
                </p>
                <p className="text-md text-gray-600 mt-2">
                  Owner: {storeDetails.ownerName}
                </p>
                <p className="text-md text-gray-600 mt-1">
                  Phone: {storeDetails.ownerPhoneNumber}
                </p>
                <p className="text-md text-gray-600 mt-1">
                  Email: {storeDetails.ownerEmailAddress}
                </p>
              </div>
              <div className="bg-blue-200 rounded-b-xl p-5">
                <div className="sm:flex sm:justify-between items-center">
                  <div>
                    <div className="flex items-center">
                      <Rating
                        name="read-only"
                        value={storeDetails.rating} // Make sure rating exists in your data
                        precision={0.5}
                        readOnly
                      />
                      <div className="text-gray-600 ml-2 text-sm md:text-base">
                        {storeDetails.ratingPerson} reviews
                      </div>
                    </div>
                  </div>
                  <Link
                    className="mt-3 sm:mt-0 py-2 px-5 md:py-3 md:px-6 bg-blue-500 hover:bg-blue-400 duration-100 font-bold text-white md:text-lg rounded-lg shadow-md"
                    to={storeDetails.mapLocation}
                  >
                    Get Directions
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-blue-200 p-6 flex flex-col sm:py-12 rounded-xl border h-full justify-center w-full md:w-fit">
            <h2 className="text-lg font-bold mb-4">Availability Timing</h2>
            <ul className="list-none text-left">
              {Object.entries(storeDetails.shopTimingDetails).map(
                ([day, timing], index) => (
                  <li key={index} className="rounded-lg group cursor-pointer">
                    <div className="flex flex-row">
                      <div className="flex flex-col ml-2 p-2 pr-6 rounded-xl">
                        <div className="flex items-center gap-6 ml-4 text-xl font-medium">
                          <div className="flex items-center">
                            <p
                              className={`w-8 h-4 rounded-xl 
                                bg-blue-400
                              `}
                            ></p>
                          </div>
                          <div>
                            <p className="uppercase">
                              {day.replace("Timing", "")}
                            </p>
                            <div className="mb-2 text-xs uppercase">
                              {timing}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoriesItemMainView;
