import { axiosInstance } from "../config/securityInstance";

export const getUserDetails = async () => {
  try {
    const response = await axiosInstance.get("/user/details");
    return response.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    return null;
  }
};

export const isAuthenticated = async () => {
  const token = localStorage.getItem("accessToken");
  if (!token) return false;

  try {
    const user = await getUserDetails();
    return user ? true : false;
  } catch (error) {
    console.error("Error verifying user authentication:", error);
    return false;
  }
};
