import React, { useState } from "react";
import OTPInput from "otp-input-react";

const EditProfile = ({
  name,
  setName,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  refetchUserDetails,
  setShowAlert,
  axiosInstance,
}) => {
  const [errors, setErrors] = useState({ email: "", phoneNumber: "" });
  const [showOTP, setShowOTP] = useState(false);
  const [OTP, setOTP] = useState("");
  const [detailsChanged, setDetailsChanged] = useState(false); // State to track if details have changed

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const regex = /^\d{10}$/;
    return regex.test(phoneNumber);
  };

  const handleUpdate = async () => {
    let emailError = "";
    let phoneNumberError = "";

    if (!validateEmail(email)) {
      emailError = "Invalid email address.";
    }

    if (!validatePhoneNumber(phoneNumber)) {
      phoneNumberError = "Invalid phone number. Must be 10 digits.";
    }

    if (emailError || phoneNumberError) {
      setErrors({ email: emailError, phoneNumber: phoneNumberError });
      return;
    }

    try {
      // Initiate OTP verification
      const response = await axiosInstance.post("/user/update", {
        name,
        email,
        phoneNumber,
      });
      if (response.status === 200) {
        setShowOTP(true); // Show OTP input after sending OTP
      } else {
        setShowOTP(false);
        setShowAlert({ success: false, failed: true, sameDetails: false });
      }
    } catch (error) {
      console.error("Error sending OTP:", error.message);
      setShowAlert({ success: false, failed: true, sameDetails: false });
      setShowOTP(false);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      const response = await axiosInstance.put("/user/update/verifyotp", {
        name,
        email,
        phoneNumber,
        otp: OTP,
      });
      if (response.status === 200) {
        await refetchUserDetails();
        setShowAlert({ success: true, failed: false, sameDetails: false });
        setShowOTP(false); // Hide OTP input after successful verification
      } else {
        setShowAlert({ success: false, failed: true, sameDetails: false });
      }
    } catch (error) {
      console.error(error.response.data.message || "Error verifying OTP");
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    // Check if any of the inputs are changed
    if (id === "name" && value !== name) {
      setDetailsChanged(true);
    } else if (id === "email" && value !== email) {
      setDetailsChanged(true);
    } else if (id === "phoneNumber" && value !== phoneNumber) {
      setDetailsChanged(true);
    } else {
      setDetailsChanged(false);
    }
  };

  return (
    <div className="py-12">
      <div className="px-8 md:px-24">
        <label
          htmlFor="name"
          className="block text-gray-700 text-sm font-semibold m-2 text-left"
        >
          User Name
        </label>
        <input
          type="text"
          id="name"
          className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-blue-500"
          required
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            handleInputChange(e);
          }}
        />
        <label
          htmlFor="email"
          className="block text-gray-700 text-sm font-semibold m-2 text-left"
        >
          Email Address
        </label>
        <input
          type="email"
          id="email"
          className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-blue-500"
          required
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setErrors({ ...errors, email: "" });
            handleInputChange(e);
          }}
        />
        {errors.email && <p className="text-red-500">{errors.email}</p>}
        <label
          htmlFor="phoneNumber"
          className="block text-gray-700 text-sm font-semibold m-2 text-left"
        >
          Phone Number
        </label>
        <input
          type="tel"
          id="phoneNumber"
          className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-blue-500"
          required
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
            setErrors({ ...errors, phoneNumber: "" });
            handleInputChange(e);
          }}
        />
        {errors.phoneNumber && (
          <p className="text-red-500">{errors.phoneNumber}</p>
        )}
        <div className="flex justify-end w-full">
          {!showOTP && detailsChanged && ( // Show button only if details have changed
            <button
              onClick={() => {
                handleUpdate();
              }}
              className="px-4 py-2 bg-blue-500 hover:bg-blue-300 active:bg-blue-700 text-white rounded-lg font-semibold text-xl my-4"
            >
              Send OTP
            </button>
          )}
        </div>
        {showOTP && (
          <div className="w-full text-2xl">
            <p className="font-semibold">Enter Your OTP</p>
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              className="mx-0 md:mx-2 my-4 items-center justify-center"
              inputClassName="border border-black rounded-lg py-6 text-xl"
            />
            <button
              onClick={handleVerifyOTP}
              className="w-full py-2 bg-blue-500 hover:bg-blue-300 active:bg-blue-700 text-white rounded-lg font-semibold text-xl my-4"
            >
              Update Details
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditProfile;