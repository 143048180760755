// src/components/ContactUs.js
import React, { useState } from "react";
import { axiosInstance } from "../../config/securityInstance";

function ContactUs() {
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhoneNo, setContactPhoneNo] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    try {
      const response = await axiosInstance.post("/contact/contact-us/", {
        contact_customer_name: contactName,
        contact_customer_email: contactEmail,
        contact_customer_phone_no: contactPhoneNo,
        contact_customer_message: contactMessage,
      });
      if (response.status === 200) {
        setContactEmail("");
        setContactMessage("");
        setContactName("");
        setContactPhoneNo("");
      }
      setSuccessMessage("Contact form submitted successfully!");
      setError(""); // Clear error message
    } catch (error) {
      console.error("Error While Submitting Contact Form", error);
      setError("Error submitting contact form. Please try again.");
      setSuccessMessage(""); // Clear success message
    }
  };

  return (
    <div className="py-12 md:py-24 lg:py-36 flex justify-center items-center">
      <div className="flex flex-col gap-12 md:gap-16 lg:gap-24 justify-center items-center w-full px-4 md:px-8 lg:px-16 xl:px-24">
        <div className="space-y-4 text-center w-full">
          <p className="text-2xl md:text-3xl lg:text-4xl font-bold">
            Contact Us
          </p>
          <p className="text-gray-600 md:text-lg">
            Feel free to contact us – submit your queries here and we will
            listen
          </p>
        </div>
        <div className="flex flex-col lg:flex-row gap-8 lg:gap-16 items-center w-full">
          <div className="w-full lg:w-1/2">
            <form
              className="flex flex-col gap-6 md:gap-8 w-full"
              onSubmit={handleSubmit}
            >
              <label
                htmlFor="contactName"
                className="border border-gray-300 rounded-xl flex items-center text-left px-3 py-2"
              >
                <p className="flex items-center gap-2">
                  <i className="mt-1 fi fi-rs-user"></i>
                </p>
                <input
                  name="contactName"
                  id="contactName"
                  className="py-2 px-3 flex-grow focus:outline-none border-none"
                  placeholder="Enter your name"
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                />
              </label>
              <div className="flex flex-wrap gap-6 lg:gap-8 w-full">
                <label
                  htmlFor="contactEmail"
                  className="border border-gray-300 rounded-xl flex items-center text-left px-3 py-2 w-full"
                >
                  <p className="flex items-center gap-2">
                    <i className="mt-1 fi fi-rs-envelope"></i>
                  </p>
                  <input
                    name="contactEmail"
                    id="contactEmail"
                    className="py-2 px-3 flex-grow focus:outline-none border-none"
                    placeholder="Enter your email"
                    type="email"
                    value={contactEmail}
                    onChange={(e) => setContactEmail(e.target.value)}
                  />
                </label>
                <label
                  htmlFor="contactPhone"
                  className="border border-gray-300 rounded-xl flex items-center text-left px-3 py-2 w-full"
                >
                  <p className="flex items-center gap-2">
                    <i className="mt-1 fi fi-rs-phone-call"></i>
                  </p>
                  <input
                    name="contactPhone"
                    id="contactPhone"
                    className="py-2 px-3 flex-grow focus:outline-none border-none"
                    placeholder="Enter your phone number"
                    type="tel"
                    value={contactPhoneNo}
                    onChange={(e) => setContactPhoneNo(e.target.value)}
                  />
                </label>
              </div>
              <label
                htmlFor="contactMessage"
                className="border border-gray-300 rounded-xl flex flex-col text-left p-3"
              >
                <p className="flex items-center gap-2">
                  <i className="mt-1 fi fi-rs-messages"></i>
                </p>
                <textarea
                  name="contactMessage"
                  id="contactMessage"
                  className="focus:outline-none border-none resize-none"
                  placeholder="Enter your message"
                  rows={6}
                  value={contactMessage}
                  onChange={(e) => setContactMessage(e.target.value)}
                />
              </label>
              {error && <p className="text-red-600">{error}</p>}
              {successMessage && (
                <p className="text-green-600">{successMessage}</p>
              )}
              <button
                type="submit"
                className="py-3 bg-blue-600 text-white font-bold rounded-xl hover:bg-blue-700 transition-colors duration-300"
              >
                Send Message
              </button>
            </form>
          </div>
          <div className="flex flex-col gap-8 lg:gap-12 w-full lg:w-1/2">
            <div className="px-6 md:px-8 lg:px-12 text-center lg:text-left bg-blue-700 rounded-2xl py-6">
              <p className="flex items-center space-x-4 justify-center lg:justify-start">
                <i className="fi fi-rs-phone-call bg-white rounded-xl text-blue-700 text-lg mt-1 py-2 px-4"></i>
                <span className="text-white text-lg">Call Us Directly At</span>
              </p>
              <p className="text-2xl md:text-3xl lg:text-4xl text-white font-semibold my-4">
                +91 9068999989
              </p>
              <a href="tel:+919992990399">
                <button className="bg-blue-500 text-white font-semibold py-2 mt-4 w-full rounded-xl hover:bg-blue-600 transition-colors duration-300">
                  Call Now
                </button>
              </a>
            </div>
            <div className="px-6 md:px-8 lg:px-12 text-center lg:text-left bg-gray-300 rounded-2xl py-6">
              <p className="flex items-center space-x-4 justify-center lg:justify-start">
                <i className="fi fi-rs-envelope bg-white rounded-xl text-black text-lg mt-1 py-2 px-4"></i>
                <span className="text-black text-lg">
                  Contact With Our Team
                </span>
              </p>
              <p className="text-base md:text-xl lg:text-2xl text-black font-semibold my-4">
                support@erohtak.com
              </p>
              <a href="mailto:support@erohtak.com">
                <button className="bg-blue-500 text-white font-semibold py-2 mt-4 w-full rounded-xl hover:bg-blue-600 transition-colors duration-300">
                  Contact Us
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
