import React, { useState } from "react";
import logo from "../../assets/images/objectindia-logo-main.png";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config/securityInstance";
import OTPInput from "otp-input-react";

function Signup() {
  const [userName, setUserName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [OTP, setOTP] = useState("");
  const [verifyOTPScreen, setVerifyOTPScreen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [resendEnabled, setResendEnabled] = useState(false);
  const navigate = useNavigate();

  const handleTextChange = (e) => {
    const value = e.target.value;
    validateName(value);
    setUserName(value);
  };

  const validateName = (text) => {
    let isValidName = text.match(/^[a-z A-Z]*$/);
    setNameError(
      isValidName
        ? ""
        : "Name should not contain any number or special character"
    );
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    validateEmail(value);
  };

  const validateEmail = (email) => {
    let isValidEmail = email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    setEmailError(isValidEmail ? "" : "Invalid email format");
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    validatePhoneNumber(value);
  };

  const validatePhoneNumber = (phone) => {
    // Regular expression to validate Indian phone numbers
    let isValidPhone = /^[6-9]\d{9}$/.test(phone); // Matches 10 digit numbers starting with 6, 7, 8, or 9
    setPhoneNumberError(isValidPhone ? "" : "Invalid phone number format");
  };

  const handleRegisterUser = async (e) => {
    e.preventDefault();
    if (!email || !userName || !phoneNumber) {
      setAlertMessage("Please fill in all required fields.");
      return;
    }
    if (emailError || nameError || phoneNumberError) {
      setAlertMessage("Please correct the errors in the form.");
      return;
    }

    try {
      const response = await axiosInstance.post("/auth/register", {
        email,
        name: userName,
        phoneNumber,
      });
      if (response.status === 200) {
        setVerifyOTPScreen(true);
        setAlertMessage("");
        setResendEnabled(true); // Enable resend OTP button
      }
    } catch (error) {
      setAlertMessage(
        error.response?.data?.message || "Error registering user"
      );
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/auth/verify-otp-register", {
        email,
        name: userName,
        phoneNumber,
        otp: OTP,
      });
      if (response.status === 201) {
        localStorage.setItem("accessToken", response.data.token);
        setAlertMessage("User registered successfully.");
        navigate("/", { replace: true });
        window.location.reload();
      }
    } catch (error) {
      setAlertMessage(error.response?.data?.message || "Error verifying OTP");
    }
  };

  const handleResendOTP = async () => {
    try {
      const response = await axiosInstance.post("/auth/resend-otp", { email });
      if (response.status === 200) {
        setAlertMessage("OTP resent successfully.");
      }
    } catch (error) {
      setAlertMessage(error.response?.data?.message || "Error resending OTP");
    }
  };

  return (
    <div className="bg-slate-900 flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full shadow-slate-500">
        <div className="flex justify-center mb-6">
          <span className="inline-block bg-gray-200 rounded-full p-3">
            <img src={logo} alt="logo" height={60} width={60} />
          </span>
        </div>
        <h2 className="text-2xl font-semibold text-center mb-4">
          {verifyOTPScreen ? "Verify OTP" : "Create a new account"}
        </h2>
        {alertMessage && (
          <div className="text-red-500 text-center mb-4">{alertMessage}</div>
        )}
        {!verifyOTPScreen ? (
          <form onSubmit={handleRegisterUser}>
            <div className="mb-4">
              <label
                htmlFor="fullName"
                className="block text-gray-700 text-sm font-semibold m-2 text-left"
              >
                Full Name <span className="text-rose-600">*</span>
              </label>
              <input
                type="text"
                id="fullName"
                className={`form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-blue-500`}
                required
                placeholder="ERohtak"
                value={userName}
                onChange={handleTextChange}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-700 text-sm font-semibold m-2 text-left"
              >
                Email Address <span className="text-rose-600">*</span>
              </label>
              <input
                type="email"
                id="email"
                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-blue-500"
                placeholder="hello@erohtak.com"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="tel"
                className="block text-gray-700 text-sm font-semibold m-2 text-left"
              >
                Phone Number <span className="text-rose-600">*</span>
              </label>
              <input
                type="tel"
                id="tel"
                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-blue-500"
                required
                placeholder="9999999999"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Send OTP
            </button>
            <p className="text-gray-600 text-xs text-center mt-4">
              Already have an Account?{" "}
              <Link to={"/login"} className="text-blue-500 hover:underline">
                Login
              </Link>
              .
            </p>
          </form>
        ) : (
          <form
            onSubmit={handleVerifyOTP}
            className="items-center justify-center"
          >
            <div className="mb-4">
              <label
                htmlFor="otp"
                className="block text-gray-700 text-sm font-semibold m-2 text-left"
              >
                Verify OTP
              </label>
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                className="mx-0 md:mx-2 items-center justify-center"
                inputClassName="border border-black rounded-lg py-6 text-xl"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Verify OTP
            </button>
            <button
              type="button"
              onClick={handleResendOTP}
              disabled={!resendEnabled}
              className="w-full bg-gray-500 text-white px-4 py-2 rounded-lg mt-2 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
            >
              Resend OTP
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default Signup;
