import React, { useState } from "react";

const ImageSimpleSlider = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // Extract all image URLs from the images array
  const imageUrls = images.map((img) => [img.img1, img.img2, img.img3]).flat();

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === imageUrls.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? imageUrls.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className="relative w-full">
      <button
        className="absolute top-1/2 transform -translate-y-1/2 left-5 bg-black bg-opacity-50 text-white py-2 px-3 rounded-full z-10"
        onClick={prevSlide}
        aria-label="Previous Slide"
      >
        &#10094;
      </button>
      <button
        className="absolute top-1/2 transform -translate-y-1/2 right-5 bg-black bg-opacity-50 text-white py-2 px-3 rounded-full z-10"
        onClick={nextSlide}
        aria-label="Next Slide"
      >
        &#10095;
      </button>
      <div className="relative h-56 overflow-hidden rounded-t-xl md:h-96">
        {imageUrls.map((image, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-500 ease-in-out ${
              index === currentSlide ? "opacity-100" : "opacity-0"
            }`}
          >
            <img
              src={image}
              alt={`Shop view ${index + 1}`}
              className="w-full h-full object-contain"
            />
          </div>
        ))}
      </div>
      <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
        {imageUrls.map((_, index) => (
          <button
            key={index}
            type="button"
            className={`w-3 h-3 rounded-full ${
              index === currentSlide ? "bg-white" : "bg-gray-400"
            }`}
            onClick={() => setCurrentSlide(index)}
            aria-current={index === currentSlide ? "true" : "false"}
            aria-label={`Slide ${index + 1}`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default ImageSimpleSlider;