import React, { useState, useEffect, useCallback } from "react";
import goot from "goot";
import spacetime from "spacetime";
import EditStore from "./editStore";
import EditProfile from "./editProfile";
import Alert from "../../components/UI/Alert";
import { useUser } from "../../config/userConfig";
import InitialsAvatar from "react-initials-avatar";
import "react-initials-avatar/lib/ReactInitialsAvatar.css";
import { axiosInstance, getUserDetails } from "../../config/securityInstance";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  const [name, setName] = useState("");
  const { user, setUser } = useUser();
  const [email, setEmail] = useState("");
  const greet = goot(spacetime().hour());
  const greetingMessage = greet.toUpperCase();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [storeEdit, setStoreEdit] = useState(false);
  const [showAlert, setShowAlert] = useState({
    success: false,
    failed: false,
    sameDetails: false,
  });

  const navigation = useNavigate();

  const handleLogout = () => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      navigation("/");
      localStorage.removeItem("accessToken");
      window.location.reload();
    }
  };

  const stores = user?.stores || [];

  const refetchUserDetails = useCallback(async () => {
    try {
      const userDetails = await getUserDetails();
      setUser(userDetails);
      return userDetails;
    } catch (error) {
      console.error("Error refetching user details:", error.message);
    }
  }, [setUser]);

  useEffect(() => {
    setName(user.name);
    setEmail(user.email);
    setPhoneNumber(user.phoneNumber);
  }, [user.name, user.email, user.phoneNumber]);

  return (
    <div className="w-screen pt-36">
      {showAlert.success && (
        <Alert
          bg="bg-green-100"
          text="text-green-700"
          border="border-green-400"
          message="Details changed successfully!"
        />
      )}
      {showAlert.failed && (
        <Alert
          bg="bg-rose-100"
          text="text-rose-700"
          border="border-rose-400"
          message="Faced an error while changing details"
        />
      )}
      {showAlert.sameDetails && (
        <Alert
          bg="bg-yellow-100"
          text="text-yellow-700"
          border="border-yellow-400"
          message="Details are the same, no changes made"
        />
      )}
      <div className="flex w-full items-center justify-between px-12 md:px-24 py-0 md:py-8 ">
        <div className="flex flex-col items-start">
          <p className="font-semibold text-base md:text-4xl">
            Hey, <span className="text-blue-500">{user?.name}</span>
          </p>
          <p className="font-semibold text-base md:text-xl">
            {"GOOD "}
            {greetingMessage === "NIGHT" ? "EVENING" : greetingMessage} {"👋"}
          </p>
          <button
            onClick={handleLogout}
            className="bg-red-500 my-4 px-4 py-1 rounded-2xl"
          >
            <p className="text-white text-sm md:text-lg font-semibold">
              LOGOUT
            </p>
          </button>
        </div>
        <div>
          {user?.avatar ? (
            <div className="overflow-hidden">
              <img
                src={user.avatar}
                className="rounded-full h-[50px] md:h-[150px] w-[50px] md:w-[150px]"
                alt="User Avatar"
              />
            </div>
          ) : (
            <div className="border border-black rounded-full px-4 py-3">
              <InitialsAvatar
                className="text-xl md:text-5xl"
                name={user?.name}
              />
            </div>
          )}
        </div>
      </div>
      <div className="-z-10 h-full overflow-auto">
        <div className="flex w-full items-center justify-center">
          <div className="rounded-xl flex items-center justify-center w-full my-2">
            <button
              className={`w-3/12 py-3 rounded-l-xl ${
                !storeEdit ? "bg-blue-500 text-white" : "bg-blue-100 text-black"
              } text-base md:text-xl font-semibold`}
              onClick={() => {
                setStoreEdit(false);
              }}
            >
              PROFILE
            </button>
            <button
              className={`w-3/12 py-3 rounded-r-xl ${
                storeEdit ? "bg-blue-500 text-white" : "bg-blue-100 text-black"
              } text-base md:text-xl font-semibold`}
              onClick={() => {
                setStoreEdit(true);
              }}
            >
              STORE
            </button>
          </div>
        </div>
        {storeEdit ? (
          <EditStore stores={stores} />
        ) : (
          <EditProfile
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            refetchUserDetails={refetchUserDetails}
            setShowAlert={setShowAlert}
            axiosInstance={axiosInstance}
          />
        )}
      </div>
    </div>
  );
};

export default UserProfile;
