// src/App.js
import React from "react";
import "./App.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { UserProvider, useUser } from "./config/userConfig";
import Navbar from "./components/default/Navbar";
import Footer from "./components/default/Footer";
import Home from "./pages/Home/home";
import AboutUs from "./pages/About-us/about";
import ContactUs from "./pages/Contact-us/ContactUs";
import Pricing from "./pages/Pricing/Pricing";
import CategoriesHome from "./pages/Categories/CategoriesHome";
import CategoriesSubListing from "./pages/Categories/CategoriesSubListing";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import NotFound from "./pages/NotFound";
import ScrollToTop from "./components/default/scrollToTop";
import CategoriesItemMainView from "./pages/Categories/CategoriesItemMainView";
import AddListingForm from "./pages/Add-Listing/AddListingForm";
import ProtectedRoute from "./components/protectedRoute";
import UserProfile from "./pages/profile/userProfile";
import Logo from "./assets/images/objectindia-logo-main.png";

function App() {
  const location = useLocation();
  const hideNavbarPaths = ["/login", "/signup", "/not-found"];

  const { user, loading } = useUser();

  if (loading) {
    return (
      <div className="bg-gray-500/30 w-full h-full fixed z-10 flex justify-center">
        <div className="flex items-center justify-center">
          <img
            src={Logo}
            height={120}
            width={120}
            className="mb-36 z-50 animate-bounce duration-75"
            alt="Loading"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <ScrollToTop />
      <div className="fixed z-30">
        {!hideNavbarPaths.includes(location.pathname) && (
          <Navbar accountDetails={user} />
        )}
      </div>
      <div className="z-10">
        <Routes>
          
          {/* User page Routes */}

          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/categories" element={<CategoriesHome />} />
          <Route path="/c/:categoriesId" element={<CategoriesSubListing />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/c" element={<Navigate to="/categories" replace />} />
          <Route path="/s/:Id" element={<CategoriesItemMainView />} />

          <Route
            path="/add-listing"
            element={
              <ProtectedRoute>
                <AddListingForm user={user} />
              </ProtectedRoute>
            }
          />

          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <UserProfile user={user} />
              </ProtectedRoute>
            }
          />

          {/* Not Found / default Routes */}
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/not-found" replace />} />
        </Routes>
      </div>
      {!hideNavbarPaths.includes(location.pathname) && (
        <Footer accountDetails={user} />
      )}
    </div>
  );
}

export default function AppWithProvider() {
  return (
    <UserProvider>
      <App />
    </UserProvider>
  );
}
