import React from "react";
import { Link } from "react-router-dom";

function TopHeadNameBarItemsMain({ items }) {
  return (
    <div className="h-48 bg-blue-200 items-center flex justify-center">
      <div className="space-y-3">
        <p className="text-5xl font-bold">
          {items.shopDetails.shopName.toUpperCase()}
        </p>
        <p className="text-lg text-gray-500 font-semibold">
          <Link to={"/"}>
            <span>HOME</span> {">"} {items.shopDetails.shopName.toUpperCase()}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default TopHeadNameBarItemsMain;
