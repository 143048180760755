const promotionListingData = [
  {
    id: 1,
    name: "Shubham Jangra",
    locationIcon: "fi-bs-globe",
    location: "Rohtak Haryana",
    phoneIcon: "fi-bs-phone-call",
    phone: "+91 9992990399",
    rating: 4.5,
    ratingPerson: 8,
    category: "Cafe",
    image: "feature-1.jpg",
    shortName: "SJ",
  },
  {
    id: 2,
    name: "Shubham Jangra",
    locationIcon: "fi-bs-globe",
    location: "Rohtak Haryana",
    phoneIcon: "fi-bs-phone-call",
    phone: "+91 9992990399",
    rating: 4.5,
    ratingPerson: 8,
    category: "Cafe",
    image: "feature-1.jpg",
    shortName: "SJ",
  },
  {
    id: 3,
    name: "Shubham Jangra",
    locationIcon: "fi-bs-globe",
    location: "Rohtak Haryana",
    phoneIcon: "fi-bs-phone-call",
    phone: "+91 9992990399",
    rating: 4.5,
    ratingPerson: 8,
    category: "Cafe",
    image: "feature-1.jpg",
    shortName: "SJ",
  },
  {
    id: 4,
    name: "Shubham Jangra",
    locationIcon: "fi-bs-globe",
    location: "Rohtak Haryana",
    phoneIcon: "fi-bs-phone-call",
    phone: "+91 9992990399",
    rating: 4.5,
    ratingPerson: 8,
    category: "Cafe",
    image: "feature-1.jpg",
    shortName: "SJ",
  },
];

export default promotionListingData;
