const categoriesItems = [
  {
    id: 2,
    label: "AC Dealers",
    category: "ac dealers",
    icon: "fi-rr-air-conditioner",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 3,
    label: "AC Repair & Services",
    category: "ac repair services",
    icon: "fi-rr-tools",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 4,
    label: "App Developer",
    category: "app developer",
    icon: "fi-brands-app-store-ios",
    hover: "p-12",
    count: 17,
    visibleScreen: "Home",
  },
  {
    id: 5,
    label: "ATM",
    category: "atm",
    icon: "fi-rr-credit-card",
    hover: "p-12",
    count: 17,
    visibleScreen: "Home",
  },
  {
    id: 6,
    label: "Automobile Spare Part Dealers",
    category: "automobile spare part dealers",
    icon: "fi-rr-car",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 7,
    label: "Bakery",
    category: "bakery",
    icon: "fi-rr-cake-birthday",
    hover: "p-12",
    count: 17,
    visibleScreen: "Home",
  },
  {
    id: 8,
    label: "Bakery Product Retailers",
    category: "bakery product retailers",
    icon: "fi-rr-bread",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 9,
    label: "Banks",
    category: "banks",
    icon: "fi-rr-bank",
    hover: "p-12",
    count: 17,
    visibleScreen: "Home",
  },
  {
    id: 10,
    label: "Battery Dealers",
    category: "battery dealers",
    icon: "fi-rr-car-battery",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 11,
    label: "Beauty Parlours",
    category: "beauty parlours",
    icon: "fi-rr-makeup-brush",
    hover: "p-12",
    count: 17,
    visibleScreen: "Home",
  },
  {
    id: 12,
    label: "Bed Dealers",
    category: "bed dealers",
    icon: "fi-rr-bed",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 13,
    label: "Book Shops",
    category: "book shops",
    icon: "fi-rr-book",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 14,
    label: "Boutiques",
    category: "boutiques",
    icon: "fi-rr-woman-head",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 15,
    label: "Builders & Developers",
    category: "builders developers",
    icon: "fi-rr-blueprint",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 16,
    label: "Building Material Dealers",
    category: "building material dealers",
    icon: "fi-rr-building",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 17,
    label: "CCTV Dealers",
    category: "cctv dealers",
    icon: "fi-rr-camera-cctv",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 18,
    label: "Car Hire",
    category: "car hire",
    icon: "fi-rr-car-alt",
    hover: "p-12",
    count: 17,
    visibleScreen: "Home",
  },
  {
    id: 19,
    label: "Car Repair & Services",
    category: "car repair & services",
    icon: "fi-rr-car-mechanic",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 20,
    label: "Caterers",
    category: "caterers",
    icon: "fi-rr-room-service",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 21,
    label: "Cement Dealers",
    category: "cement dealers",
    icon: "fi-rr-building",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 22,
    label: "Chemists",
    category: "chemists",
    icon: "fi-rr-capsules",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 24,
    label: "Clinics",
    category: "clinics",
    icon: "fi-rr-hospital-user",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 25,
    label: "Colleges",
    category: "colleges",
    icon: "fi-rr-school",
    hover: "p-12",
    count: 17,
    visibleScreen: "Home",
  },
  {
    id: 27,
    label: "Computer Dealers",
    category: "computer dealers",
    icon: "fi-rr-computer",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 28,
    label: "Computer Repair & Services",
    category: "computer repair & services",
    icon: "fi-rr-tools",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 29,
    label: "Computer Training Institutes",
    category: "computer training institutes",
    icon: "fi-rr-graduation-cap",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 30,
    label: "Cosmetic Dealers",
    category: "cosmetic dealers",
    icon: "fi-rr-lipstick",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 31,
    label: "Cyber Cafes",
    category: "cyber cafes",
    icon: "fi-rr-globe",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 32,
    label: "Dairy Product Retailers",
    category: "dairy product retailers",
    icon: "fi-rs-milk-alt",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 33,
    label: "Dentists",
    category: "dentists",
    icon: "fi-rr-tooth",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 34,
    label: "Designer Key Chain Dealers",
    category: "designer key chain dealers",
    icon: "fi-rr-key",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 35,
    label: "Developer",
    category: "developer",
    icon: "fi-rr-square-code",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 36,
    label: "Dining Table Dealers",
    category: "dining table dealers",
    icon: "fi-rr-table-picnic",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 37,
    label: "Electrical Shops",
    category: "electrical shops",
    icon: "fi-rr-plug-alt",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 38,
    label: "Electricians",
    category: "electricians",
    icon: "fi-rr-utility-pole",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 39,
    label: "Electronics",
    category: "electronics",
    icon: "fi-rr-laptop-mobile",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id:40,
    label: "Police Station",
    category: "police station",
    icon: "fi-rr-user-police",
    hover: "p-12",
    count: 17,
    visibleScreen: "Home",
  },
  {
    id: 41,
    label: "Estate Agents",
    category: "estate agents",
    icon: "fi-rr-home",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 42,
    label: "Event Organisers",
    category: "event organisers",
    icon: "fi-rr-calendar",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 43,
    label: "Fabricators",
    category: "fabricators",
    icon: "fi-rr-hammer",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 44,
    label: "Fast Food",
    category: "fast food",
    icon: "fi-rr-hamburger-soda",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 45,
    label: "Fertilizer Dealers",
    category: "fertilizer dealers",
    icon: "fi-rr-leaf",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 46,
    label: "Furniture Dealers",
    category: "furniture dealers",
    icon: "fi-rr-sofa",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 47,
    label: "Garages",
    category: "garages",
    icon: "fi-rr-garage",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 48,
    label: "General Physician Doctors",
    category: "general physician doctors",
    icon: "fi-rr-stethoscope",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 49,
    label: "General Stores",
    category: "general stores",
    icon: "fi-rr-store-alt",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 50,
    label: "Gents Tailors",
    category: "gents tailors",
    icon: "fi-rr-tie",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 51,
    label: "Gift Shops",
    category: "gift shops",
    icon: "fi-rr-gift",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 52,
    label: "Gold Jewellery Showrooms",
    category: "gold jewellery showrooms",
    icon: "fi-rr-diamond",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 53,
    label: "Gyms",
    category: "gyms",
    icon: "fi-rr-gym",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 54,
    label: "Hardware Dealers",
    category: "hardware dealers",
    icon: "fi-rr-chair",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 55,
    label: "Hospitals",
    category: "hospitals",
    icon: "fi-rr-hospital",
    hover: "p-12",
    count: 17,
    visibleScreen: "Home",
  },
  {
    id: 56,
    label: "Hotels",
    category: "hotels",
    icon: "fi-rr-hotel",
    hover: "p-12",
    count: 17,
    visibleScreen: "Home",
  },
  {
    id: 57,
    label: "Indian Restaurants",
    category: "indian restaurants",
    icon: "fi-rr-restaurant",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 58,
    label: "Institutes",
    category: "institutes",
    icon: "fi-rr-building",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 59,
    label: "Insurance Agents",
    category: "insurance agents",
    icon: "fi-rr-life-ring",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 60,
    label: "Interior Designers",
    category: "interior designers",
    icon: "fi-rr-ruler-triangle",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 61,
    label: "Inverter Dealers",
    category: "inverter dealers",
    icon: "fi-rr-battery-bolt",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 62,
    label: "Jewellery Showrooms",
    category: "jewellery showrooms",
    icon: "fi-rr-ring",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 63,
    label: "Ladoo Retailers",
    category: "ladoo retailers",
    icon: "fi-rr-cookie",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 64,
    label: "Lawyers",
    category: "lawyers",
    icon: "fi-rr-lawyer-man",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 66,
    label: "Loans",
    category: "loans",
    icon: "fi-rr-money",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 67,
    label: "Mobile Phone Accessory Dealers",
    category: "mobile phone accessory dealers",
    icon: "fi-rr-password-smartphone",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 68,
    label: "Mobile Phone Dealers",
    category: "mobile phone dealers",
    icon: "fi-rr-mobile",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 69,
    label: "Mobile Phone Repair & Services",
    category: "mobile phone repair & services",
    icon: "fi-rr-it-alt",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 70,
    label: "Motorcycle Repair & Services",
    category: "motorcycle repair & services",
    icon: "fi-rr-motorcycle",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 71,
    label: "Nutritional Supplement Retailers",
    category: "nutritional supplement retailers",
    icon: "fi-rr-pills",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 72,
    label: "Paint Dealers",
    category: "paint dealers",
    icon: "fi-rr-paint-roller",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 73,
    label: "Pen Dealers",
    category: "pen dealers",
    icon: "fi-rr-pen-clip",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 74,
    label: "Photo Studios",
    category: "photo studios",
    icon: "fi-rr-camera",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 75,
    label: "Video Editor",
    category: "video editor",
    icon: "fi-rr-film",
    hover: "p-12",
    count: 17,
    visibleScreen: "Home",
  },
  {
    id: 76,
    label: "Animation",
    category: "animation",
    icon: "fi-brands-animate",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 77,
    label: "Photographers",
    category: "photographers",
    icon: "fi-rr-camera",
    hover: "p-12",
    count: 17,
    visibleScreen: "Home",
  },
  {
    id: 79,
    label: "Pipe Dealers",
    category: "pipe dealers",
    icon: "fi-rr-manhole",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 80,
    label: "Placement Services",
    category: "placement services",
    icon: "fi-rr-briefcase",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 81,
    label: "Printing Services",
    category: "printing services",
    icon: "fi-rr-print",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 82,
    label: "Readymade Garment Retailers",
    category: "readymade garment retailers",
    icon: "fi-rr-tshirt",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 83,
    label: "Refrigerator Repair & Services",
    category: "refrigerator repair & services",
    icon: "fi-rr-refrigerator",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 84,
    label: "Restaurants",
    category: "restaurants",
    icon: "fi-rr-restaurant",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 85,
    label: "Rice Retailers",
    category: "rice retailers",
    icon: "fi-rr-wheat",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 86,
    label: "Salons",
    category: "salons",
    icon: "fi-rr-barber-shop",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 89,
    label: "Schools",
    category: "schools",
    icon: "fi-rr-school",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 90,
    label: "Scooter Repair & Services",
    category: "scooter repair & services",
    icon: "fi-rr-moped",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 91,
    label: "Shirt Retailers",
    category: "shirt retailers",
    icon: "fi-rr-shirt",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 92,
    label: "Shoe Dealers",
    category: "shoe dealers",
    icon: "fi-rr-boot-heeled",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 93,
    label: "Sticker Dealers",
    category: "sticker dealers",
    icon: "fi-rr-sticker",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 94,
    label: "Sweet Shops",
    category: "sweet shops",
    icon: "fi-rr-candy",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 95,
    label: "TV Dealers",
    category: "tv dealers",
    icon: "fi-rr-screen",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 96,
    label: "Tailors",
    category: "tailors",
    icon: "fi-rr-scissors",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 97,
    label: "Temples",
    category: "temples",
    icon: "fi-rr-vihara",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 98,
    label: "Tent House",
    category: "tent house",
    icon: "fi-rr-tents",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 99,
    label: "Tile Dealers",
    category: "tile dealers",
    icon: "fi-rr-floor-alt",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 100,
    label: "Tool Dealers",
    category: "tool dealers",
    icon: "fi-rr-tools",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 101,
    label: "Transporters",
    category: "transporters",
    icon: "fi-rr-shipping-fast",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 102,
    label: "Travel Agents",
    category: "travel agents",
    icon: "fi-rr-plane",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 103,
    label: "Tutorials",
    category: "tutorials",
    icon: "fi-rr-play-alt",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 104,
    label: "Two Wheeler Repair & Services",
    category: "two wheeler repair & services",
    icon: "fi-rr-biking",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 105,
    label: "Tyre Dealers",
    category: "tyre dealers",
    icon: "fi-rr-tire",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 106,
    label: "Website Developer",
    category: "website developer",
    icon: "fi-rr-laptop-code",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 107,
    label: "Wire Dealers",
    category: "wire dealers",
    icon: "fi-rr-s",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
  {
    id: 108,
    label: "Xerox Centres",
    category: "xerox centres",
    icon: "fi-rr-print",
    hover: "p-12",
    count: 17,
    visibleScreen: "NoHome",
  },
];

export { categoriesItems };
