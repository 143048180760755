import React from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../config/securityInstance";

const EditStore = ({ stores, setShowAlert }) => {
  const locationIcon = "fi-bs-globe";
  const phoneIcon = "fi-bs-phone-call";

  const handleDelete = async (storeID) => {
    try {
      const userId = localStorage.getItem("authLogin");
      await axiosInstance.delete(`/user/store/${storeID}`, {
        data: { userId, storeID },
      });

      window.location.reload();
    } catch (error) {
      console.error("Error while deleting store:", error);
    }
  };

  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 justify-center items-center gap-4 my-6 overflow-auto">
      {stores.map((store) => (
        <div key={store._id} className="w-full">
          <Link
            to={`/s/${store._id}`}
            className="flex flex-row border-2 rounded-xl overflow-hidden min-w-[40vw] h-full md:max-h-48 mx-3 md:mx-16"
          >
            {store.images.map((image) => (
              <div key={image.img1} className="w-44 overflow-hidden">
                <img
                  src={image.img1}
                  alt="shop"
                  className="w-full h-44 md:h-72 object-cover"
                />
              </div>
            ))}
            <div className="flex flex-row justify-between w-full p-2">
              <div className="text-left py-2">
                <p className="text-base md:text-3xl font-semibold">
                  {store.shopDetails.shopName}
                </p>
                <div className="text-xs md:text-base font-normal md:font-semibold text-gray-500 text-left gap-2 px-0 md:px-4">
                  <p className="flex items-center">
                    <span className="p-2">
                      <i className={`fi ${locationIcon}`}></i>
                    </span>
                    {store.shopLocationDetails.shopLocation}
                  </p>
                  <p className="flex items-center">
                    <span className="p-2">
                      <i className={`fi ${phoneIcon}`}></i>
                    </span>
                    {store.ownerPhoneNumber}
                  </p>
                  <a
                    href={store.shopDetails.shopWebsite}
                    className="flex items-center text-blue-600 hover:underline"
                  >
                    <span className="p-2">
                      <i className={`fi ${locationIcon}`}></i>
                    </span>
                    {store.shopDetails.shopWebsite}
                  </a>
                </div>
              </div>
            </div>
          </Link>
          <div className="w-full items-center flex flex-row justify-center gap-8 text-base">
            <div className="flex w-10/12 gap-4 my-2">
              {/* <Link
                to={"/"}
                className="py-3 px-8 bg-blue-500 text-white font-semibold rounded-xl w-full"
              >
                <p>EDIT</p>
              </Link> */}
              <button
                onClick={() => {
                  handleDelete(store._id);
                }}
                className="py-3 px-8 bg-red-500 text-white font-semibold rounded-xl w-full"
              >
                <p>DELETE</p>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EditStore;
