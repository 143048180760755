import OTPInput from "otp-input-react";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/objectindia-logo-main.png";
import { axiosInstance, getUserDetails } from "../../config/securityInstance";

function Login() {
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [verifyOTPScreen, setVerifyOTPScreen] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(30);
  const [canResendOTP, setCanResendOTP] = useState(false);

  useEffect(() => {
    if (resendCountdown > 0 && verifyOTPScreen) {
      const timer = setTimeout(() => {
        setResendCountdown(resendCountdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (resendCountdown === 0) {
      setCanResendOTP(true);
    }
  }, [resendCountdown, verifyOTPScreen]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value);
  };

  const validateEmail = (email) => {
    let isValidEmail = email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    setEmailError(isValidEmail ? "" : "Invalid email format");
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setAlertMessage("Please enter your email.");
      return;
    }
    if (emailError) {
      setAlertMessage("Please enter a valid email.");
      return;
    }

    try {
      const response = await axiosInstance.post("/auth/login", { email });
      if (response.status === 200) {
        setVerifyOTPScreen(true);
        setAlertMessage("");
        setResendCountdown(30); // Start the countdown when OTP is sent
        setCanResendOTP(false); // Disable resend button initially
      }
    } catch (error) {
      setAlertMessage(error.response?.data?.message || "Error logging in user");
    }
  };

  const handleResendOTP = async () => {
    if (!canResendOTP) return;

    try {
      const response = await axiosInstance.post("/auth/resend-otp", { email });
      if (response.status === 200) {
        setAlertMessage("OTP resent successfully.");
        setResendCountdown(30); // Restart the countdown
        setCanResendOTP(false); // Disable resend button again
      }
    } catch (error) {
      setAlertMessage(error.response?.data?.message || "Error resending OTP");
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/auth/verify-otp-login", {
        email,
        otp: OTP,
      });
      if (response.status === 200) {
        localStorage.setItem("accessToken", response.data.responseData.token);
        localStorage.setItem("authLogin", response.data.responseData.user._id);
        setAlertMessage("Logged in successfully.");
        await getUserDetails(); // Ensure this is an actual call to fetch user details
        navigate("/", { replace: true });
        window.location.reload();
      }
    } catch (error) {
      setAlertMessage(error.response?.data?.message || "Error verifying OTP");
    }
  };

  return (
    <div className="bg-slate-900 flex items-center justify-center h-screen">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full shadow-slate-500">
        <div className="flex justify-center mb-6">
          <span className="inline-block bg-gray-200 rounded-full p-3">
            <img src={logo} alt="logo" height={60} width={60} />
          </span>
        </div>
        <h2 className="text-2xl font-semibold text-center mb-4">
          {verifyOTPScreen ? "Verify OTP" : "Login to your account"}
        </h2>
        {alertMessage && (
          <div className="text-red-500 text-center mb-4">{alertMessage}</div>
        )}
        {!verifyOTPScreen ? (
          <form onSubmit={handleLoginSubmit}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-700 text-sm font-semibold m-2 text-left"
              >
                Email Address <span className="text-rose-600">*</span>
              </label>
              <input
                type="email"
                id="email"
                className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:outline-blue-500"
                required
                placeholder="hello@erohtak.com"
                value={email}
                onChange={handleEmailChange}
              />
              <p className="text-left text-red-600 text-sm font-semibold m-2">
                {emailError}
              </p>
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Send OTP
            </button>
            <p className="text-gray-600 text-xs text-center mt-4">
              Don't have an Account?{" "}
              <Link to={"/signup"} className="text-blue-500 hover:underline">
                Register
              </Link>
            </p>
          </form>
        ) : (
          <form
            onSubmit={handleVerifyOTP}
            className="items-center justify-center"
          >
            <div className="mb-4">
              <label
                htmlFor="otp"
                className="block text-gray-700 text-sm font-semibold m-2 text-left"
              >
                Verify OTP
              </label>
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                className="mx-0 md:mx-2 items-center justify-center"
                inputClassName="border border-black rounded-lg py-6 text-xl"
              />
              <button
                type="button"
                className={`text-end w-10/12 text-sm ${
                  canResendOTP ? "text-blue-500 hover:underline" : "text-gray-400"
                }`}
                onClick={handleResendOTP}
                disabled={!canResendOTP}
              >
                {canResendOTP ? "Resend OTP" : `Resend OTP in ${resendCountdown}s`}
              </button>
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Verify OTP
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default Login;
