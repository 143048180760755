import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const MapComponent = ({ setMapLocation }) => {
  // eslint-disable-next-line
  const [map, setMap] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const API = process.env.REACT_APP_GOOGLE_MAP_API;

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const initialPosition = { lat: latitude, lng: longitude };
          setCurrentPosition(initialPosition);
          setMarkerPosition(initialPosition);
          setMapLocation(
            `https://maps.google.com/maps?q=${latitude},${longitude}`
          );
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, [setMapLocation]);

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    setMapLocation(`https://maps.google.com/maps?q=${lat},${lng}`);
  };

  const handleMarkerDragEnd = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    setMapLocation(`https://maps.google.com/maps?q=${lat},${lng}`);
  };

  return (
    <LoadScript googleMapsApiKey={API}>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "400px" }}
        center={currentPosition}
        zoom={15}
        onClick={handleMapClick}
        onLoad={(map) => setMap(map)}
      >
        {markerPosition && (
          <Marker
            position={markerPosition}
            draggable={true}
            onDragEnd={handleMarkerDragEnd}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
